<template>
  <div>
    <headers />
    <router-view :key="key" />
  </div>
</template>

<script>
import Headers from '@/components/Header/orgindex'

export default {
  name: 'LayoutPublic',
  components: { Headers },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
