<template>
  <div class="header-container">
    <router-link tag="div" to="/" class="cnt">
      <img class="logo-img" :src="logoImg" alt="LOGO" >
    </router-link>
    <div class="cnt login-cnt">
<!--      <template v-if="!token || token === ''">-->
<!--        <router-link class="login" to="/login">登录</router-link>-->
<!--        <router-link to="/register">免费注册</router-link>-->
<!--      </template>-->
        <span>{{ name }}</span>
        <user-dropdown class="avatar-container"/>
    </div>
  </div>
</template>

<script>
import logoImgActive from '@/assets/images/consoleLogo.png'
import UserDropdown from '@/components/Header/user-dropdown'
import { mapState } from 'vuex'

export default {
  name: 'Header1',
  components: { UserDropdown },
  data() {
    return {
      logoImg: logoImgActive,
      headerActive: false,
      headerAbout: false,
    }
  },
  computed: {
    token(){
      return localStorage.getItem('token')
    },
    ...mapState({
      // token: state => state.user.token,
      name: state => state.user.name,
    })
  },
  methods: {
    getDefaultUrl() {
      let url = ''
      if (this.token) {
        url = { name: 'console' }
      } else {
        url = '/login'
      }
      return url
    },
    // 切换企业点击
    showOrgLInk() {
      this.$refs.chooseCompany.dialogVisible(true)
    },
    // 企业切换回调
    checkOrg() {

    }
  }
}
</script>

<style lang="scss" scoped>
$height: 60px;
$bgActiveColor: #303030;

.header-container {
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 100px;
  min-width: 1200px;
  color: #000;
  transition: all 0.4s;
  background-color: #ffffff;
  &.active {
    background-color: $bgActiveColor;
  }
}
.cnt {
  display: flex;
  align-items: center;
  padding: 15px 0;
  height: $height;
  line-height: 30px;
}
.logo-img {
  height: 40px;
  /*height: 30px;*/
  //cursor: pointer;
}
.logo-title {
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
}
.login-cnt {
  .btn {
    transition: all 0.4s;
    width:106px;
    height:40px;
    color:#000;
    border-radius:2px;
    text-align: center;
    line-height:40px;
  }
  .login {
    margin-right: 10px;
    padding: 10px 20px;
    color: #fff;
    line-height: 1;
    border-radius: 100px;
    background-color: #409EFF;
  }
  .controlplat {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.orgName{
  //cursor: pointer;
}
.avatar-container {
  margin-left: 10px;
}
</style>
